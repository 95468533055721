
uniform float uTime;

varying vec3 vPosition;

void main()

{
    // stripes

    float stripes = mod((vPosition.y - uTime * 0.02) * 20.0, 1.0);
    stripes = pow(stripes, 10.0);

    // final color

    gl_FragColor = vec4(0.3, stripes, 1.0, vPosition.x);

    #include <tonemapping_fragment>
    #include <colorspace_fragment>

}